import {
  Button,
  Card,
  H3,
  Input,
  Label,
  Separator,
  Switch,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { useParams } from 'react-router-dom';
import { useCurrentAssignment } from 'store/selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FeedbackFormSchema as FeedbackFormSchemaGenerator } from 'form-schemas/';
import { useEffect, useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { createFeedback, updateFeedback } from 'apis/';
import { useDispatch } from 'react-redux';
import { addSubmissionFeedback } from 'store/reducers/user-assignments-reducer';
import PropTypes from 'prop-types';
import FileUploader from '../attachments/FileUploader';
import Attachments from '../attachments/Attachments';

const FeedbackForm = ({ submission, defaultFeedback, userAssignmentId }) => {
  const [feedbackCreateError, setFeedbackCreateError] = useState(false);
  const [feedbackCreateLoading, setFeedbackCreateLoading] = useState(false);
  const [feedbackUpdateLoading, setFeedbackUpdateLoading] = useState(false);
  const [feedback, setFeedback] = useState(defaultFeedback);
  const [feedbackAttachments, setFeedbackAttachments] = useState(
    defaultFeedback?.attachments || [],
  );
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const [objectiveIds, setObjectiveIds] = useState([]);
  const FeedbackFormSchema = FeedbackFormSchemaGenerator(
    currentAssignment?.max_points,
  );
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  });

  const handleCheckedChange = (checked, objectiveId) => {
    if (checked) {
      setObjectiveIds((prev) => {
        const result = [...prev, objectiveId];
        setValue('objective_ids', result);
        return result;
      });
    } else {
      setObjectiveIds((prev) => {
        const result = prev.filter((id) => id !== objectiveId);
        setValue('objective_ids', result);
        return result;
      });
    }
  };

  const isChecked = (objectiveId) => objectiveIds.includes(objectiveId);

  const newFeedback = async () => {
    try {
      const payload = { submission_id: submission.id };
      setFeedbackCreateLoading(true);
      setFeedbackCreateError(false);
      const result = await createFeedback(channelId, payload);
      setFeedbackAttachments(() => result?.attachments);
      setFeedback(result);
      setFeedbackCreateLoading(false);
    } catch (e) {
      setFeedbackCreateLoading(false);
      setFeedbackCreateError(true);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  const handleFeedbackUpload = async (attachment) => {
    setFeedbackAttachments((prev) => [...(prev || []), attachment]);
  };

  const onSubmit = async (data) => {
    try {
      const payload = { ...data, submission_id: submission.id };
      setFeedbackUpdateLoading(true);
      const result = await updateFeedback(channelId, feedback.id, payload);
      dispatch(
        addSubmissionFeedback({
          userAssignmentId: userAssignmentId,
          submissionId: submission.id,
          feedback: result,
        }),
      );
      toast({ description: 'Feedback created successfully' });
      setFeedbackUpdateLoading(false);
    } catch (e) {
      setFeedbackUpdateLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when creating feedback',
          variant: 'destructive',
        });
      });
    }
  };

  useEffect(() => {
    if (!defaultFeedback) newFeedback();
  }, []);

  useEffect(() => {
    setValue('points', feedback?.points);
    setValue('comment', feedback?.comment);
    setValue('objective_ids', feedback?.objectives || []);
    setObjectiveIds(() => feedback?.objectives || []);
  }, [feedback]);

  return (
    <>
      {feedbackCreateError && (
        <div>
          <p>Error occurred when creating submission</p>
        </div>
      )}

      {!feedbackCreateError && feedbackCreateLoading && (
        <div>
          <p>Loading...</p>
        </div>
      )}

      {!feedbackCreateError && !feedbackCreateLoading && (
        <div>
          <H3 className='mt-4'>Your Feedback</H3>
          <Separator className='my-4' />
          <Card>
            <div className='mb-4'>
              <Label htmlFor={null}>Objectives that needs feedback</Label>
              <div className='mt-3'>
                {currentAssignment?.objectives?.map((objective) => {
                  return (
                    <div key={objective.id} className='mb-3'>
                      <Switch
                        label={objective.name}
                        checked={isChecked(objective.id)}
                        onCheckedChange={(e) =>
                          handleCheckedChange(e, objective.id)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='mb-4'>
              <Input
                error={errors?.points?.message}
                type='number'
                label='Points'
                {...register('points')}
              />
            </div>

            <div className='mb-4'>
              <TextArea
                error={errors?.comment?.message}
                label='Comment'
                {...register('comment')}
              />
            </div>

            <div className='mb-4'>
              <FileUploader
                attachableParams={{ feedback_id: feedback?.id }}
                onUpload={handleFeedbackUpload}
              />
              <Attachments className='mt-3' attachments={feedbackAttachments} />
            </div>

            <div className='mb-3'>
              <Button
                useLoader={true}
                loading={feedbackUpdateLoading}
                loaderText='Loading...'
                onClick={handleSubmit(onSubmit, (e) => console.log(e))}
              >
                Submit Feedback
              </Button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

FeedbackForm.defaultProps = {
  defaultFeedback: undefined,
};

FeedbackForm.propTypes = {
  submission: PropTypes.object.isRequired,
  userAssignmentId: PropTypes.number.isRequired,
  defaultFeedback: PropTypes.object,
};

export default FeedbackForm;
