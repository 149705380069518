import { Accordion, Button, Card, Emoji, Lead } from '@gonurture/design-system';
import Attachments from 'components/attachments/Attachments';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SubmissionForm from './SubmissionForm';
import { updateUserAssignmentSubmission } from 'store/reducers/user-assignments-reducer';
import { useDispatch } from 'react-redux';
import FeedbackModal from 'components/teacher-feedback/FeedbackModal';
import FeedbackCard from 'components/teacher-feedback/FeedbackCard';
import { useCurrentUserAssignment } from 'store/selectors';
import StudentReflection from 'components/student-reflection/StudentReflection';
import StorageService from 'services/StorageService';

const SubmissionCard = ({
  submission,
  userAssignmentId,
  allowEdit,
  allowFeedback,
}) => {
  const [feedbackModalOpened, setFeedbackModalOpened] = useState(false);
  const [cardState, setCardState] = useState('view');
  const dispatch = useDispatch();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const assignment = currentUserAssignment?.work;
  const user = StorageService.getObject('user');

  const handleEdit = () => setCardState('edit');

  const handleSubmissionUpdated = (submission) => {
    setCardState('view');
    dispatch(
      updateUserAssignmentSubmission({ id: userAssignmentId, submission }),
    );
  };

  const hasFeedback = () => !!submission.feedback;

  return (
    <>
      {cardState === 'view' && (
        <Card className='mb-4'>
          <div className='mb-4'>
            <Lead>Comment</Lead>
            <div>{submission.comment}</div>
          </div>

          <div className='mb-4'>
            <Lead>Confidence Level Emoji</Lead>
            <div>
              <Emoji confidence={submission.confidence_level_emoji} />
            </div>
          </div>

          <div className='mb-4'>
            <Attachments attachments={submission.attachments} />
          </div>

          {submission.feedback && user.role === 'student' && (
            <>
              <div className='mb-4'>
                <Accordion
                  className='w-full'
                  trigger={<Lead>Teacher Feedback</Lead>}
                  content={
                    <FeedbackCard
                      feedback={submission.feedback}
                      assignment={assignment}
                    />
                  }
                />
              </div>

              <div className='mb-4'>
                <Accordion
                  className='w-full'
                  trigger={<Lead>Your Reflection</Lead>}
                  content={
                    <StudentReflection
                      reflection={submission.feedback.reflection}
                      feedbackId={submission.feedback.id}
                    />
                  }
                />
              </div>
            </>
          )}

          {allowEdit && !hasFeedback() && (
            <div className='mb-4'>
              <Button onClick={handleEdit}>Edit</Button>
            </div>
          )}

          {allowFeedback && (
            <div className='mb-4'>
              <Button onClick={() => setFeedbackModalOpened(true)}>
                Give Feedback
              </Button>
              <FeedbackModal
                opened={feedbackModalOpened}
                onClose={() => setFeedbackModalOpened(false)}
                userAssignmentId={userAssignmentId}
                submission={submission}
              />
            </div>
          )}
        </Card>
      )}

      {cardState === 'edit' && (
        <SubmissionForm
          defaultSubmission={submission}
          context='edit'
          canCancel={true}
          onSubmissionMade={handleSubmissionUpdated}
          onCancel={() => setCardState('view')}
        />
      )}
    </>
  );
};

SubmissionCard.propTypes = {
  submission: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  allowFeedback: PropTypes.bool.isRequired,
  userAssignmentId: PropTypes.number.isRequired,
};

export default SubmissionCard;
