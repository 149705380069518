import PropTypes from 'prop-types';
import { Button, Card, Emoji, Lead } from '@gonurture/design-system';
import StorageService from 'services/StorageService';

const ReflectionCard = ({ reflection, onEditClicked }) => {
  const user = StorageService.getObject('user');

  return (
    <Card className='mb-4'>
      <div className='mb-4'>
        <Lead>Comment</Lead>
        <div>{reflection.comment}</div>
      </div>

      <div className='mb-4'>
        <Lead>Reflection Emoji</Lead>
        <div>
          <Emoji confidence={reflection.emoji} />
        </div>
      </div>

      {user.role === 'student' && (
        <div className='mb-4'>
          <Button onClick={onEditClicked}>Edit</Button>
        </div>
      )}
    </Card>
  );
};

ReflectionCard.defaultProps = {
  onEditClicked: () => {},
};

ReflectionCard.propTypes = {
  reflection: PropTypes.object.isRequired,
  onEditClicked: PropTypes.func,
};

export default ReflectionCard;
