import PropTypes from 'prop-types';
import ReflectionCard from './ReflectionCard';
import ReflectionForm from './ReflectionForm';
import StorageService from 'services/StorageService';
import { useState } from 'react';

const StudentReflection = ({ reflection, feedbackId }) => {
  const user = StorageService.getObject('user');
  const isStudent = user?.role === 'student';
  const [showForm, setShowForm] = useState(isStudent && !reflection);

  const handleCancel = () => setShowForm(false);

  return (
    <div>
      {!showForm && reflection && (
        <ReflectionCard
          reflection={reflection}
          onEditClicked={() => setShowForm(true)}
        />
      )}

      {showForm && (
        <ReflectionForm
          feedbackId={feedbackId}
          onCancel={handleCancel}
          reflection={reflection}
        />
      )}

      {!reflection && !isStudent && (
        <div>Student has not made a Reflection</div>
      )}
    </div>
  );
};

StudentReflection.propTypes = {
  reflection: PropTypes.object.isRequired,
  feedbackId: PropTypes.number.isRequired,
};

export default StudentReflection;
