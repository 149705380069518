import { createRequest } from 'services/ApiService';
export const submissionCreate = async (channelId, payload) => {
  try {
    return await createRequest({
      url: `submissions`,
      method: 'post',
      payload,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const submissionUpdate = async (channelId, submissionId, payload) => {
  try {
    return await createRequest({
      url: `submissions/${submissionId}`,
      method: 'patch',
      payload,
      queryParams: {
        group_id: channelId,
      },
    });
  } catch (e) {
    return Promise.reject(e);
  }
};
