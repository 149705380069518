import { array, number, object, string } from 'yup';

const FeedbackFormSchema = (maxPoint) => {
  return object({
    objective_ids: array().of(number()).nullable(),
    points: number()
      .required('You need to set the points')
      .max(maxPoint)
      .nullable(),
    comment: string().required('Comment is required').nullable(),
  });
};

export default FeedbackFormSchema;
