import {
  Avatar,
  Button,
  Card,
  H3,
  Separator,
  Sheet,
  useToast,
} from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import {
  useCurrentAssignment,
  useCurrentUserAssignment,
} from 'store/selectors';
import StudentSubmission from 'components/student-submission/StudentSubmission';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userAssignmentShow } from 'apis/';
import { useDispatch } from 'react-redux';
import { addUserAssignments } from 'store/reducers/user-assignments-reducer';
import { useErrorHandler } from 'hooks/';

const UserAssignmentModal = ({ opened, defaultUserAssignmentId, onClose }) => {
  const [userAssignmentId, setUserAssignmentId] = useState(null);
  const [prevButtonLoading, setPrevButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const { assignmentId, channelId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const userAssignment = useCurrentUserAssignment(userAssignmentId);
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const { toast } = useToast();

  const locate = async (direction) => {
    const movement = { prev: -1, next: 1 };
    const buttonLoadingFunction = {
      prev: setPrevButtonLoading,
      next: setNextButtonLoading,
    };
    const availableUserAssignments = currentAssignment?.user_works;

    try {
      // No need to move to next or prev if only one submission
      if (availableUserAssignments?.length === 1) return;

      const currentIndex = availableUserAssignments.findIndex(
        (userAssignment) => userAssignment.id === userAssignmentId,
      );
      if (currentIndex === -1) return;

      let newIndex = currentIndex + movement[direction];
      if (newIndex === -1)
        newIndex = availableUserAssignments?.length - 1 || currentIndex;
      if (newIndex >= availableUserAssignments?.length) newIndex = 0;
      const newUserAssignment = availableUserAssignments[newIndex];

      buttonLoadingFunction[direction](true);

      const result = await userAssignmentShow(channelId, newUserAssignment.id);
      dispatch(addUserAssignments([result]));
      setUserAssignmentId(newUserAssignment.id);
      buttonLoadingFunction[direction](false);
    } catch (e) {
      buttonLoadingFunction[direction](false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  const prev = () => locate('prev');

  const next = () => locate('next');

  const CloseIcon = () => (
    <div className='cursor-pointer' onClick={onClose}>
      <X className='h-4 w-4' />
      <span className='sr-only'>Close</span>
    </div>
  );

  const Footer = () => {
    return (
      <div className='flex items-center justify-between w-full'>
        <Button
          withLoader={true}
          loading={prevButtonLoading}
          loaderText='Loading...'
          variant='secondary'
          onClick={prev}
        >
          Prev
        </Button>

        <Button
          withLoader={true}
          loading={nextButtonLoading}
          loaderText='Loading...'
          variant='secondary'
          onClick={next}
        >
          Next
        </Button>
      </div>
    );
  };

  useEffect(() => {
    setUserAssignmentId(defaultUserAssignmentId);
  }, [defaultUserAssignmentId]);

  return (
    <Sheet footer={<Footer />} opened={opened} closeComponent={<CloseIcon />}>
      <Card className='mt-3 mb-5'>
        <div className='flex flex-wrap items-center'>
          <Avatar src={userAssignment?.user?.avatar?.url} />
          <H3 className='mx-3 grow'>{userAssignment?.user?.display_name}</H3>
          <span
            className={`inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20`}
          >
            {userAssignment?.submitted ? 'Submitted' : 'Not Submitted'}
          </span>
        </div>
      </Card>

      <H3 className='mt-4'>Submissions</H3>
      <Separator className='my-4' />

      <StudentSubmission userAssignmentId={userAssignment?.id} />
    </Sheet>
  );
};

UserAssignmentModal.defaultProps = {
  opened: false,
};

UserAssignmentModal.propTypes = {
  opened: PropTypes.bool,
  defaultUserAssignmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserAssignmentModal;
