import { Card, H4, Lead } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import Attachments from '../attachments/Attachments';

const FeedbackCard = ({ feedback, assignment }) => {
  const objectivesWithFeedback = assignment.objectives
    .filter((objective) => feedback.objectives.includes(objective.id))
    .map((objective) => objective.name);

  const objectivesWithNoFeedback = assignment.objectives
    .filter((objective) => !feedback.objectives.includes(objective.id))
    .map((objective) => objective.name);

  return (
    <Card>
      <div className='mb-4'>
        <Lead>Comment</Lead>
        <div>{feedback.comment}</div>
      </div>

      <div className='mb-4'>
        <Lead>Points</Lead>
        <div>{feedback.points}</div>
      </div>

      {objectivesWithFeedback.length > 0 && (
        <div className='mb-4'>
          <Lead>Objectives With Feedback</Lead>
          <ul className='list-disc'>
            {objectivesWithFeedback.map((objective) => (
              <li key={objective}>{objective}</li>
            ))}
          </ul>
        </div>
      )}

      {objectivesWithNoFeedback.length > 0 && (
        <div className='mb-4'>
          <Lead>Objectives With No Feedback</Lead>
          <ul className='list-disc'>
            {objectivesWithNoFeedback.map((objective) => (
              <li key={objective}>{objective}</li>
            ))}
          </ul>
        </div>
      )}

      <div className='mb-4'>
        <H4 className='mt-4'>Teacher Attachments</H4>
        <Attachments attachments={feedback.attachments} />
      </div>
    </Card>
  );
};

FeedbackCard.propTypes = {
  feedback: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
};

export default FeedbackCard;
