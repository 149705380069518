import { useCurrentUserAssignment } from 'store/selectors';
import { useDispatch } from 'react-redux';
import { Button } from '@gonurture/design-system';
import SubmissionForm from './SubmissionForm';
import { addUserAssignmentSubmission } from 'store/reducers/user-assignments-reducer';
import SubmissionCard from './SubmissionCard';
import { useState } from 'react';
import StorageService from 'services/StorageService';
import PropTypes from 'prop-types';

const StudentSubmission = ({ userAssignmentId }) => {
  const [showNewSubmissionForm, setShowNewSubmissionForm] = useState(false);
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);
  const dispatch = useDispatch();
  const user = StorageService.getObject('user');

  const studentSubmitted = currentUserAssignment?.submissions?.length > 0;
  const userAssignmentBelongsToUser =
    currentUserAssignment?.user?.id === user?.id;

  const handleSubmissionCreated = (submission) => {
    setShowNewSubmissionForm(false);
    dispatch(addUserAssignmentSubmission({ id: userAssignmentId, submission }));
  };

  return (
    <>
      {!studentSubmitted && userAssignmentBelongsToUser && (
        <SubmissionForm onSubmissionMade={handleSubmissionCreated} />
      )}

      {studentSubmitted && userAssignmentBelongsToUser && (
        <>
          <div className='mb-4'>
            <Button onClick={() => setShowNewSubmissionForm(true)}>
              Add Another Submission
            </Button>
          </div>

          {showNewSubmissionForm && userAssignmentBelongsToUser && (
            <div className='mb-4'>
              <SubmissionForm
                onSubmissionMade={handleSubmissionCreated}
                canCancel={true}
                onCancel={() => setShowNewSubmissionForm(false)}
              />
            </div>
          )}
        </>
      )}

      {studentSubmitted &&
        currentUserAssignment.submissions.map((submission) => (
          <SubmissionCard
            allowEdit={userAssignmentBelongsToUser}
            allowFeedback={user?.role === 'teacher'}
            key={submission.id}
            userAssignmentId={userAssignmentId}
            submission={submission}
          />
        ))}
    </>
  );
};

StudentSubmission.propTypes = {
  userAssignmentId: PropTypes.string.isRequired,
};

export default StudentSubmission;
