import { NurtureIcon } from '@gonurture/design-system';
import Attachments from 'components/attachments/Attachments';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';
import LearningObjectives from 'components/learning-objectives/LearningObjectives';
import SubmissionForm from 'components/student-submission/SubmissionForm';

const AssignmentDetails = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);

  return (
    <div className='mt-10'>
      <div className='flex'>
        <div className='flex flex-col'>
          <div className='rounded-full p-2 border bg-[#F4EBFF]'>
            <NurtureIcon icon='purple-notes' />
          </div>
          <div className='flex justify-center h-full my-1'>
            <div className='border-l border-2 border-[#9E77ED]'></div>
          </div>
        </div>
        <div className='pt-0.5 ml-4 flex-grow'>
          <div className='md:w-2/3 md:mr-5'>
            <div className='font-semibold text-xl'>The Assignment</div>
            <div className='text-sm font-normal text-[#475467]'>
              The assessment description learning objectives and attachments
              your teacher has set for you.
            </div>
          </div>
          <div className='md:flex md:space-x-5 mt-7'>
            <div className='md:w-1/2'>
              {/*Assignment Description*/}
              <div className='text-base font-normal mb-6 text-[#475467]'>
                {currentUserAssignment?.work.description}
              </div>

              {/*Attachments*/}
              {currentUserAssignment?.work.attachments?.length > 0 && (
                <div className='mb-6'>
                  <div className='font-semibold text-xl mb-3'>
                    Teacher Attachments
                  </div>
                  <Attachments
                    attachments={currentUserAssignment?.work.attachments}
                  />
                </div>
              )}

              {/*Grading*/}
              <div className='md:mb-24'>
                <div className='font-semibold text-xl mb-3'>Grading</div>
                <div className='text-base font-normal mb-6 text-[#475467]'>
                  The assessment is graded out of{' '}
                  <span className='font-semibold'>
                    {currentUserAssignment?.work.max_points} marks
                  </span>
                </div>
              </div>
            </div>
            <div className='mt-6 mb-20 md:mb-0 md:mt-6 md:w-1/2'>
              <LearningObjectives
                learningObjectives={currentUserAssignment?.work.objectives}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='flex'>
        <div className='flex flex-col'>
          <div className='rounded-full p-2 border bg-[#7F56D9]'>
            <NurtureIcon icon='white-pen' />
          </div>
          <div className='flex justify-center h-full my-1'>
            <div className='border-l border-2 border-[#EAECF0]'></div>
          </div>
        </div>
        <div className='pt-0.5 ml-4 mb-8 flex-grow'>
          <div className=''>
            <div className='font-semibold text-xl'>My Submission</div>
            <div className='text-sm font-normal text-[#475467]'>
              Submit your assessment and add your confidence score
            </div>
          </div>
          <div className='mt-7'>
            <SubmissionForm
              defaultSubmission={currentUserAssignment?.submissions?.[0]}
              userAssignmentId={userAssignmentId}
              onSubmissionMade={() => {}}
            />
          </div>
        </div>
      </div>

      <div className='flex'>
        <div className='flex flex-col'>
          <div className='rounded-full p-2 border bg-[#F2F4F7]'>
            <NurtureIcon icon='grey-circle-check' />
          </div>
          {/*<div className='flex justify-center h-full my-1'>*/}
          {/*  <div className='border-l border-2 border-[#EAECF0]'></div>*/}
          {/*</div>*/}
        </div>
        <div className='pt-0.5 ml-4 flex-grow'>
          <div className=''>
            <div className='font-semibold text-xl text-[#98A2B3]'>
              Assessment Submitted
            </div>
            <div className='text-sm font-normal text-[#98A2B3]'>
              Not yet submitted...
            </div>
          </div>
        </div>
      </div>

      {/*<div className='mt-5'>*/}
      {/*  <H3 className='mt-6 mb-3'>Submissions</H3>*/}
      {/*  <StudentSubmission userAssignmentId={userAssignmentId} />*/}
      {/*</div>*/}
    </div>
  );
};

export default AssignmentDetails;
