import {
  Button,
  Card,
  ConfidenceSelector,
  Label,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import FileUploader from '../attachments/FileUploader';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'hooks/';
import PropTypes from 'prop-types';
import Attachments from '../attachments/Attachments';
import { Controller, useForm } from 'react-hook-form';
import { submissionFormSchema } from 'form-schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { submissionCreate, submissionUpdate } from 'apis/';

const SubmissionForm = ({
  defaultSubmission,
  canCancel,
  onSubmissionMade,
  onCancel,
}) => {
  const [submissionCreateError, setSubmissionCreateError] = useState(false);
  const [submissionCreateLoading, setSubmissionCreateLoading] = useState(false);
  const [submissionUpdateLoading, setSubmissionUpdateLoading] = useState(false);
  const [submission, setSubmission] = useState(defaultSubmission || {});
  const [submissionAttachments, setSubmissionAttachments] = useState(
    defaultSubmission?.attachments || [],
  );
  const [newAttachments, setNewAttachments] = useState([]);
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const { channelId, userAssignmentId } = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(submissionFormSchema),
  });

  const createSubmission = async () => {
    try {
      setSubmissionCreateLoading(true);
      setSubmissionCreateError(false);
      const payload = { user_work_id: userAssignmentId };
      const result = await submissionCreate(channelId, payload);
      setSubmission(result);
      setSubmissionAttachments(() => result.attachments);
      setSubmissionCreateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setSubmissionCreateError(true);
        setSubmissionCreateLoading(false);
        toast({
          description: 'Error occurred when creating submission',
          variant: 'error',
        });
      });
    }
  };

  const handleSubmissionUpload = async (attachment) => {
    setNewAttachments((prev) => [...prev, attachment]);
  };

  const onSubmit = async (data) => {
    try {
      if (submissionAttachments.length < 1 && newAttachments.length < 1) {
        return toast({
          description: 'Please upload at least one attachment',
          variant: 'destructive',
        });
      }

      setSubmissionUpdateLoading(true);
      const payload = { user_work_id: userAssignmentId, ...data };
      const result = await submissionUpdate(channelId, submission?.id, payload);
      toast({ description: 'Submission created successfully' });
      onSubmissionMade(result);
      setSubmission(result);
      setSubmissionUpdateLoading(false);
    } catch (e) {
      errorHandler(e, () => {
        setSubmissionUpdateLoading(false);
        toast({
          description: 'Error occurred when creating submission',
          variant: 'destructive',
        });
      });
    }
  };

  const handleCancel = () => onCancel();

  useEffect(() => {
    if (!defaultSubmission) createSubmission();
  }, []);

  useEffect(() => {
    setValue('comment', submission?.comment);
    setValue('confidence_level_emoji', submission?.confidence_level_emoji);
  }, [submission]);

  return (
    <>
      {submissionCreateError && (
        <div>
          <p>Error occurred when creating submission</p>
        </div>
      )}

      {!submissionCreateError && submissionCreateLoading && (
        <div>
          <p>Loading...</p>
        </div>
      )}

      {!submissionCreateLoading && !submissionCreateError && (
        <Card>
          <div className='mb-4 flex'>
            <div className='w-full xl:w-1/2'>
              <Label htmlFor='ConfidenceSelector'>Confidence</Label>
              <Controller
                name='confidence_level_emoji'
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ConfidenceSelector
                      onSelect={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={errors.confidence_level_emoji?.message}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className='mb-4'>
            <FileUploader
              attachableParams={{ submission_id: submission?.id }}
              onUpload={handleSubmissionUpload}
              onReset={() =>
                setSubmissionAttachments((prev) => [...prev, ...newAttachments])
              }
            />
            <Attachments className='mt-3' attachments={submissionAttachments} />
          </div>

          <div className='mb-4'>
            <TextArea
              label='Is there anything you would like to tell your teacher about how you did on this assessment?'
              error={errors.comment?.message}
              {...register('comment')}
            />
          </div>

          <div>
            <Button
              withLoader={true}
              loading={submissionUpdateLoading}
              loaderText='Submitting...'
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
            {canCancel && (
              <Button
                className='ml-3'
                variant='destructive'
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

SubmissionForm.defaultProps = {
  context: 'new',
  defaultSubmission: null,
  onCancel: () => {},
  canCancel: false,
  onSubmissionMade: () => {},
};

SubmissionForm.propTypes = {
  context: PropTypes.string,
  defaultSubmission: PropTypes.object,
  onCancel: PropTypes.func,
  canCancel: PropTypes.bool,
  onSubmissionMade: PropTypes.func,
};

export default SubmissionForm;
