import { H3, Separator, Sheet } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import SubmissionCard from '../student-submission/SubmissionCard';
import FeedbackForm from './FeedbackForm';
import StudentReflection from '../student-reflection/StudentReflection';

const FeedbackModal = ({ opened, submission, userAssignmentId, onClose }) => {
  const CloseIcon = () => (
    <div className='cursor-pointer' onClick={onClose}>
      <X className='h-4 w-4' />
      <span className='sr-only'>Close</span>
    </div>
  );

  return (
    <Sheet opened={opened} closeComponent={<CloseIcon />}>
      <H3 className='mt-4'>Submission</H3>
      <Separator className='my-4' />

      <SubmissionCard
        allowEdit={false}
        allowFeedback={false}
        userAssignmentId={userAssignmentId}
        submission={submission}
      />

      <FeedbackForm
        userAssignmentId={userAssignmentId}
        submission={submission}
        defaultFeedback={submission.feedback}
      />

      <div>
        <H3 className='mt-4'>Student Reflection</H3>
        <Separator className='my-4' />

        <StudentReflection
          reflection={submission.feedback?.reflection}
          feedbackId={submission.feedback?.id}
        />
      </div>
    </Sheet>
  );
};

FeedbackModal.defaultProps = {
  opened: false,
};

FeedbackModal.propTypes = {
  opened: PropTypes.bool,
  submission: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  userAssignmentId: PropTypes.number.isRequired,
};

export default FeedbackModal;
