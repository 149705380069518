import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Label,
  ReflectionSelector,
  TextArea,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reflectionFormSchema } from 'form-schemas/';
import { createReflection, updateReflection } from 'apis/';
import { useDispatch } from 'react-redux';
import { addFeedbackReflection } from 'store/reducers/user-assignments-reducer';

const ReflectionForm = ({ reflection, feedbackId, onCancel }) => {
  const [reflectionCreateLoading, setReflectionCreateLoading] = useState(false);

  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const { channelId, userAssignmentId } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(reflectionFormSchema),
  });

  const handleCancel = () => onCancel();

  const onSubmit = async (data) => {
    try {
      setReflectionCreateLoading(true);
      const payload = { feedback_id: feedbackId, ...data };
      const result = reflection?.id
        ? await updateReflection(channelId, reflection.id, payload)
        : await createReflection(channelId, payload);
      dispatch(
        addFeedbackReflection({
          userAssignmentId,
          feedbackId,
          reflection: result,
        }),
      );
      setReflectionCreateLoading(false);
      toast({ description: 'Reflection saved successfully' });
      handleCancel();
    } catch (e) {
      errorHandler(e, () => {
        setReflectionCreateLoading(false);
        toast({
          description: 'Error occurred when saving reflection',
          variant: 'destructive',
        });
      });
    }
  };

  useEffect(() => {
    if (reflection) {
      setValue('emoji', reflection.emoji);
      setValue('comment', reflection.comment);
    }
  }, [reflection]);

  return (
    <Card>
      <div className='mb-4'>
        <TextArea label='Comment' {...register('comment')} />
      </div>

      <div className='mb-4'>
        <Label htmlFor='ReflectionSelector'>Reflection Emoji</Label>
        <Controller
          name='emoji'
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ReflectionSelector
                onSelect={onChange}
                onBlur={onBlur}
                value={value}
              />
            );
          }}
        />
      </div>

      <div>
        <Button
          withLoader={true}
          loading={reflectionCreateLoading}
          loaderText='Submitting...'
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
        {reflection?.id && (
          <Button className='ml-3' variant='destructive' onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </div>
    </Card>
  );
};

ReflectionForm.propTypes = {
  reflection: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  feedbackId: PropTypes.number.isRequired,
};

export default ReflectionForm;
