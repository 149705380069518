import { createSlice } from '@reduxjs/toolkit';

export const aiAssignmentSlice = createSlice({
  name: 'aiAssignment',
  initialState: null,
  reducers: {
    setAiAssignment: (state, action) => {
      state = action.payload;
      return state;
    },
    clearAiAssignment: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setAiAssignment, clearAiAssignment } = aiAssignmentSlice.actions;

export default aiAssignmentSlice.reducer;
